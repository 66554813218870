import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))}/>
      {/* <Route path={`${match.url}hotels`} component={asyncComponent(() => import('./Hotel'))}/> */}
      <Route path={`${match.url}hotels/room-category/:id`} component={asyncComponent(() => import('./Hotel/roomsCategory'))}/>
      <Route path={`${match.url}hotels/:id`} component={asyncComponent(() => import('./Hotel/hotelDetails'))}/>
      <Route path={`${match.url}hotels`} component={asyncComponent(() => import('./Hotel'))}/>
      <Route path={`${match.url}add-hotel`} component={asyncComponent(() => import('./Hotel/addHotel'))}/>
      <Route path={`${match.url}agents`} component={asyncComponent(() => import('./Agent'))}/>
      <Route path={`${match.url}transactions`} component={asyncComponent(() => import('./Transactions'))}/>
      <Route path={`${match.url}roles-groups`} component={asyncComponent(() => import('./RoleGroups'))}/>
      <Route path={`${match.url}setting/amenities`} component={asyncComponent(() => import('./HotelAmenities'))}/>
      <Route path={`${match.url}setting/room-amenities`} component={asyncComponent(() => import('./RoomAmenities'))}/>
      <Route path={`${match.url}setting/room-categories`} component={asyncComponent(() => import('./RoomCategory'))}/>
      <Route path={`${match.url}setting/property-type`} component={asyncComponent(() => import('./PropertyType'))}/>
      {/*<Route path={`${match.url}setting/room-categories`} component={asyncComponent(() => import('./R'))}/>*/}
      <Route path={`${match.url}amenities`} component={asyncComponent(() => import('./Dashboard'))}/>
      <Route path={`${match.url}amenities`} component={asyncComponent(() => import('./Dashboard'))}/>
      <Route path={`${match.url}users`} component={asyncComponent(() => import('./Users'))}/>
      <Route path={`${match.url}settings`} component={asyncComponent(() => import('./SystemSetting'))}/>
    </Switch>
  </div>
);

export default App;
