import axios from "axios";

const axios_ = axios.create({
  baseURL: `https://bongohotels.ewallet-dev.com/api/v1`
  // baseURL: `http://localhost:8096/api/v1`
});

axios_.interceptors.request.use((config) => {


  const token = localStorage.getItem("token")
  config.params = config.params || {};
  config.headers['Content-type'] = `application/json`
  config.headers['Authorization'] = `Bearer ${token}`
  return config;
});

export default axios_;
