import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
  SIGNIN_USER,
  SIGNOUT_USER,
} from "constants/ActionTypes";
import {showAuthMessage, userSignInSuccess, userSignOutSuccess} from "../../appRedux/actions/Auth";
import axios_ from "../../util/axios";


const loginRequest = async (email, password) => {
  const payload = {
    email, password
  }

  console.log(payload)
  return await axios_.post("/auth/login", payload)
    .then(authUser => {
      console.log(authUser)
      return authUser.data
    })
    .catch(error => {
      console.log(error.response.data)
      return error.response.data
    });

}
// const signOutRequest = async () =>
//   await  auth.signOut()
//     .then(authUser => authUser)
//     .catch(error => error);


function* signInUserWithEmailPassword({payload}) {
  const {email, password} = payload;
  try {
    const signInUser = yield call(loginRequest, email, password);
    console.log(signInUser)
    if (signInUser) {
      localStorage.setItem("user_id", signInUser.user.id)
      localStorage.setItem("token", signInUser.token)
      localStorage.setItem("user", JSON.stringify(signInUser.user))
      yield put(userSignInSuccess(signInUser));
    } else {
      yield put(showAuthMessage(signInUser));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    const signOutUser = null;
    if (signOutUser === undefined) {
      localStorage.removeItem('user_id');
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      yield put(userSignOutSuccess(signOutUser));
      window.location.reload()
    } else {
      yield put(showAuthMessage(signOutUser.message));
      localStorage.removeItem('user_id');
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      yield put(userSignOutSuccess(signOutUser));
      window.location.reload()
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}


export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signInUser),
    fork(signOutUser)]);
}
